import ImageWrapper from "../../Image/ImageWrapper";
import { connect } from 'react-redux';

function SizeChart(props) {
  const BLOCK_CLASS = "size-chart";

  const getSizeChartName = () => {
    let productCategories = props?.product?.productDetails?.categories;
    let productName = props?.product?.productDetails?.name;
    // console.log(productCategories)
    // console.log(productName)
    if (productCategories) {
      if (productCategories.includes("Kurtis")){
        if (productName.includes("Angrakha") || productName.includes("Kali")) {
          return 'kali_and_angrakha';
        } else {
          return 'kurtis';
        }
      } else if (productCategories.includes("Dresses")){
          return 'dresses';
      } else if (productCategories.includes("Tops")) {
        if (productName.includes("Shirt")) {
          return 'shirts';
        } else if (productName.includes("Crop tops")){
          return 'crop-tops';
        }
        else {
          return 'tops';
        }
      }
      else if (productCategories.includes("Pants")){
        if (productName.includes("Wrap")) {
          return 'wrap-pants';
        } else if (productName.includes("Wrap Skirt")){
          return 'wrap-skirts';
        } else {
          return 'pants';
        }
      } else if (productCategories.includes("Co Ords")){
        if (productName.includes("Jumpsuit")){
          return 'jumpsuits';
        } else {
          return 'co-ords';
        }
      } else if (productCategories.includes("Kimonos")){
        if (productName.includes("Kimono")){
          return 'kimono';
        } else if (productName.includes('Wrap Kaftan')) {
          return 'kaftan';
        } else {
          return 'kimono-dress';
        }
      } else if (productCategories.includes("Skirts")){
        if (productName.includes("Wrap Skirt")){
          return 'wrap-skirts';
        } 
      }
    }
  };

  return (
    <div className={`${BLOCK_CLASS}`}>
      <h3>Size Chart</h3>

      <ImageWrapper
            name={getSizeChartName()}
            alt=""
            classes={`${BLOCK_CLASS}__image`}
            srcType={"internal"}
          ></ImageWrapper>

    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const { product } = state;
  return { product }
}

export default connect(mapStateToProps)(SizeChart);
