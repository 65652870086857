import { memo, useEffect, useState } from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleMobileDrawerMenu } from "../../store/actions";
import ProductSlider from "../../components/ProductSlider/ProductSlider";

const Categories = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    setCategoriesList(props.categories)
  }, [props.categories]);

  return (
    <section className="categories">
      <div className="">
        <div className="category-main">
          <div className="category-title">
            <h3>Shop by</h3>
            <h4>Categories</h4>
          </div>
          
          <ProductSlider showArrows={false} type="category" data={props?.sliderData?.categories} ctaUrl={''}/>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state, ownProps) {
  const { categories, isMobileMenuOpen, sliderData } = state;
  return { categories: categories, isMobileMenuOpen, sliderData };
}

export default connect(mapStateToProps)(Categories);
