import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ImageWrapper from "../../components/Image/ImageWrapper";
import Story from "../../components/Story/Story";
import Freshness from "./Freshness";
import Categories from "./Categories";
import Reminder from "./Reminder";
import Features from "./Features";
import Testimonials from "./Testimonials";
import Newsletter from "./Newsletter";
import Givetime from "./Givetime";
import Banner from "./Banner";
import ProductSlider from "../../components/ProductSlider/ProductSlider";

const Home = (props) => {
  const videoRef = useRef();
  const [heroBannerImgData, setHeroBannerData] = useState([
    {
      mobile_name: 'hero-banner1-mobile',
      desktop_name: 'hero-banner1-web',
      mobile_extenstion: '.png',
      desktop_extension: '.png',
      redirect_link: '/collections/New%20Arrivals/e76fa09f-ddfb-4b4d-8162-af0b8716fad5',
    },
    {
      mobile_name: 'hero-banner2-mobile',
      desktop_name: 'hero-banner2-web',
      mobile_extenstion: '.png',
      desktop_extension: '.png',
      redirect_link: '/collection/Clothing',
    },
  ]);
  
  const [firstBannerImgData, setFirstBannerData] = useState([
    {
      mobile_name: 'live-sale-mobile',
      desktop_name: 'live-sale-web',
      mobile_extenstion: '.png',
      desktop_extension: '.png',
      redirect_link: '/collections/Best%20Deals/843c0384-c3af-4734-8085-0248f80c2e70',
    },
    {
      mobile_name: 'kimono_banner_mobile',
      desktop_name: 'kimono_banner_desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Clothing/Kimonos/1863934d-66d5-46d4-9d57-bf6559742b75',
    },
    {
      mobile_name: 'dress-banner-mobile',
      desktop_name: 'dress-banner-desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Clothing/Dresses/4379fbae-3ca2-4a3b-9e15-eb8c46106b7f',
    }
  ]);
  const [secondBannerImgData, setSecondBannerData] = useState([
    {
      mobile_name: 'bags-banner-mobile',
      desktop_name: 'bags-banner-desktop',
      mobile_extenstion: '.png',
      desktop_extension: '.webp',
      redirect_link: '/products/Bags/5b8b2369-bffe-46b1-beab-6ad4cd779a9f',
    }
  ]);

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.catalogue !== undefined && props.catalogue !== null) {
      let firstBannerData = [
        {
          mobile_name: 'live-sale-mobile',
          desktop_name: 'live-sale-web',
          mobile_extenstion: '.png',
          desktop_extension: '.png',
          redirect_link: '/collections/Best%20Deals/843c0384-c3af-4734-8085-0248f80c2e70',
        },
        {
          mobile_name: "kimono_banner_mobile",
          desktop_name: "kimono_banner_desktop",
          mobile_extenstion: ".png",
          desktop_extension: ".webp",
          redirect_link:
            `/products/Clothing/Kimonos/${props?.catalogue?.categories?.Clothing?.subcategories?.Kimonos?.id}`,
        },
        {
          mobile_name: "dress-banner-mobile",
          desktop_name: "dress-banner-desktop",
          mobile_extenstion: ".png",
          desktop_extension: ".webp",
          redirect_link:
            `/products/Clothing/Dresses/${props?.catalogue?.categories?.Clothing?.subcategories?.Dresses?.id}`,
        },
      ];

      let secondBannerData = [
        {
          mobile_name: 'bags-banner-mobile',
          desktop_name: 'bags-banner-desktop',
          mobile_extenstion: '.png',
          desktop_extension: '.webp',
          redirect_link: `/products/Bags/${props?.catalogue?.categories?.Bags?.id}`,
        }
      ];

      setFirstBannerData(firstBannerData);
      setSecondBannerData(secondBannerData);
    }
  }, [props.catalogue]);

  const getProductsList = (collectionName) => {
    let data = props?.sliderData?.collections !== null ? props?.sliderData?.collections[props.catalogue?.collections[collectionName]?.id] : [];

    return data;
  }

  const getCollectionPageUrl = (collectionName) => {
    return `collections/${collectionName}/${props.catalogue?.collections[collectionName]?.id}`;
  }

  return (
    <div className="home">
      <section className="hero">
        <Banner placement={1} imgData={heroBannerImgData}/>
        <ImageWrapper
          name={`${
            window.innerWidth < 1024 ? "banner-btm-mobile" : "banner-btm-desk"
          }`}
          alt="Clothing-img4"
          classes={`bg-element`}
        />
      </section>
      <Freshness />
      <Categories />
      <ProductSlider type="product" name={"New Arrivals"} data={getProductsList('New Arrivals')} ctaUrl={getCollectionPageUrl('New Arrivals')} showArrows={true}/>
      <Banner placement={1} imgData={firstBannerImgData}/>
      <ProductSlider type="product" name={"Don't miss it!"} data={getProductsList('Best Deals')} ctaUrl={getCollectionPageUrl('Best Deals')} showArrows={true}/>
      <Banner placement={2} imgData={secondBannerImgData}/>
      <Reminder />
      <Features />
      <section className="story-section">
        <Story />
      </section>
      <Testimonials />
      <Givetime />
      <Newsletter />
    </div>
  );
};

// export default Home;

function mapStateToProps(state, ownProps) {
  const { catalogue, sliderData } = state;
  return { catalogue, sliderData };
}

export default connect(mapStateToProps)(Home);
