import { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";

const BLOCK_CLASS = 'header';

function Banner() {
  const Settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    vertical: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const Banner_Wrapper = useRef(null);
  const [announcments, updateAnnouncments] = useState([
    {
      text: "Explore the soulful artistry of our premium handcrafted collection",
      classes: "",
    },
    {
      text: "Get up to 30% OFF",
      classes: "",
    },
    {
      text: "Our latest collection just hit the racks! ✨",
      classes: "",
    },
    {
      text: "Explore the soulful artistry of our premium handcrafted collection",
      classes: "",
    },
    {
      text: "Get up to 30% OFF",
      classes: "",
    },
    {
      text: "Our latest collection just hit the racks! ✨",
      classes: "",
    },
    {
      text: "Explore the soulful artistry of our premium handcrafted collection",
      classes: "",
    },
    {
      text: "Get up to 30% OFF",
      classes: "",
    },
    {
      text: "Our latest collection just hit the racks! ✨",
      classes: "",
    },
    {
      text: "Explore the soulful artistry of our premium handcrafted collection",
      classes: "",
    },
    {
      text: "Get up to 30% OFF",
      classes: "",
    },
    {
      text: "Our latest collection just hit the racks! ✨",
      classes: "",
    }
  ]);

  useEffect(() => {
    
  }, []);


  return (
    <div className={`${BLOCK_CLASS}__banner`}>
      <Slider {...Settings}>
        {announcments.map((item) => {
          return <span className={item.classes}>{item.text}</span>;
        })}
      </Slider>
    </div>
  );
}

export default Banner;
