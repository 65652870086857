import { createReducer } from '@reduxjs/toolkit';
import {
  addToLocalCart,
  checkoutSuccess,
  clearOrderState,
  clearToastMessage,
  createAddressSuccess,
  getAddressesSuccess,
  getCartDetailsSuccess,
  getCatalogueSuccess,
  getCategoriesSuccess,
  getOrdersSuccess,
  getProductDetailsInProgress,
  getProductDetailsSuccess,
  getProductsSuccess,
  getRecommendedProductsSuccess,
  getSubCategoriesSuccess,
  getUserDetailsSuccess,
  getWishlistSuccess,
  hideDrawer,
  logout,
  paymentFailure,
  paymentSuccess,
  removeCartItemSuccess,
  setAddressFormVisibility,
  showDrawer,
  showToastMessage,
  toggleMobileDrawerMenu,
  togglePlaceOrderModal,
  updateQuantityOfCartItem,
  verifyOTPSuccess,
  showAppLoader,
  hideAppLoader,
  cancelOrderSuccess,
  subscribeEmailSuccess,
  checkPincodeSuccess,
  clearPincodeStatus,
  getCollectionProductsSuccess,
  getSubCategoriesForSliderSuccess
} from "./actions";
import { retry } from 'redux-saga/effects';

const initialState = {
  addresses: [],
  cartDetails: null,
  catalogue: null,
  categories: [],
  sliderData: {
    collections : null,
    categories: null
  },
  currentModalType: '',
  isAddressFormVisible: false,
  isCartVisible: false,
  isFilterVisible: false,
  isLoading: false,
  isMobileMenuOpen: false,
  isModalVisible: false,
  isPaymentSuccess: null,
  isUserLoggedIn: false,
  orders: [],
  paymentURL: null,
  product: null,
  productsList: [],
  recommendations: null,
  subcategories: [],
  toastMessage: null,
  userDetails: null,
  wishlist: null,
  isAppLoaderVisibile: false,
  isSubscriptionSuccessful: false,
  pincodeCheckResponse: ''
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(verifyOTPSuccess, (state, action) => {
    state.isUserLoggedIn = true;
    localStorage.setItem("token", action.data.api_token);
    if (state.currentModalType === "login") {
      state.isModalVisible = false;
    }
  });

  builder.addCase(logout, (state, action) => {
    state.isUserLoggedIn = false;
    state.cartDetails = null;
  });

  builder.addCase(getCategoriesSuccess, (state, action) => {
    let data = action.data.categories;
    state.categories = data;
  });

  builder.addCase(getSubCategoriesSuccess, (state, action) => {
    let data = action.data.categories;

    state.subcategories = [...data];
  });

  builder.addCase(getProductsSuccess, (state, action) => {
    state.productsList = action.data.products;
  });

  builder.addCase(getProductDetailsSuccess, (state, action) => {
    state.product = action.data;
    state.isLoading = false;
  });

  builder.addCase(getProductDetailsInProgress, (state, action) => {
    state.isLoading = true;
  });

  builder.addCase(togglePlaceOrderModal, (state, action) => {
    state.isModalVisible = !state.isModalVisible;
    if (action.payload) {
      state.currentModalType = action.payload.type;
    }
  });

  builder.addCase(showDrawer, (state, action) => {
    let drawerType = action.payload.type;

    switch (drawerType) {
      case "cart":
        state.isCartVisible = true;
        break;

      default:
        break;
    }
  });

  builder.addCase(getCartDetailsSuccess, (state, action) => {
    let data = action.data;
    state.cartDetails = data;
  });

  builder.addCase(addToLocalCart, (state, action) => {
    let data = action.data;

    let areItemsInCart = state.cartDetails?.items ? true : false;
    const cartItems = state.cartDetails?.items;

    if (areItemsInCart) {
      let willItemUpdateDuringMap = false;

      let updatedCart = cartItems.map((item) => {
        if (item.cart_item_id === data.cart_item_id) {
          willItemUpdateDuringMap = true;
          return { ...item, quantity: data.quantity };
        } else {
          return item;
        }
      });

      updatedCart = willItemUpdateDuringMap
        ? updatedCart
        : [...updatedCart, data];
      state.cartDetails = {
        items: updatedCart,
      };
    } else {
      state.cartDetails = {
        items: [data],
      };
    }
  });

  builder.addCase(hideDrawer, (state, action) => {
    let drawerType = action.payload.type;

    switch (drawerType) {
      case "cart":
        state.isCartVisible = false;
        break;

      default:
        break;
    }
  });

  builder.addCase(updateQuantityOfCartItem, (state, action) => {
    let new_quantity = action.payload.item.quantity;
    let product = action.payload.item;

    let updatedCart = state.cartDetails.items.map((item) => {
      if (item.variant_sku === product.variant_sku) {
        return {
          ...item,
          quantity: new_quantity,
        };
      } else {
        return item;
      }
    });
    state.cartDetails = {
      ...state.cartDetails,
      items: [...updatedCart],
    };
  });

  builder.addCase(removeCartItemSuccess, (state, action) => {
    const itemId = action.data;
    let filteredList = state.cartDetails?.items.filter(
      (item) => item.cart_item_id !== itemId
    );
    state.cartDetails = {
      ...state.cartDetails,
      total: filteredList.length === 0 ? 0 : state.cartDetails?.total,
      items: filteredList,
    };
  });

  builder.addCase(checkoutSuccess, (state, action) => {
    const data = action.data;
    state.paymentURL = data.payment_url;
  });

  builder.addCase(clearOrderState, (state, action) => {
    state.paymentURL = null;
  });

  builder.addCase(paymentSuccess, (state, action) => {
    state.paymentURL = null;
    state.cartDetails = null;

    let response = action.data;

    if (response.success) {
      state.isPaymentSuccess = true;
    } else {
      state.isPaymentSuccess = false;
    }
  });

  builder.addCase(paymentFailure, (state, action) => {
    state.paymentURL = null;
    state.isPaymentSuccess = false;
  });

  builder.addCase(getCatalogueSuccess, (state, action) => {
    state.catalogue = action.data;
  });

  builder.addCase(toggleMobileDrawerMenu, (state, action) => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  });

  builder.addCase(getAddressesSuccess, (state, action) => {
    state.isAddressFormVisible = false;
    state.addresses = action.data.addresses;
  });

  builder.addCase(setAddressFormVisibility, (state, action) => {
    state.isAddressFormVisible = !state.isAddressFormVisible;
  });

  builder.addCase(getOrdersSuccess, (state, action) => {
    state.orders = action.data;
  });
  builder.addCase(getUserDetailsSuccess, (state, action) => {
    state.userDetails = { ...action.data.user };
  });

  builder.addCase(getWishlistSuccess, (state, action) => {
    state.wishlist = action.data;
  });

  builder.addCase(getRecommendedProductsSuccess, (state, action) => {
    state.recommendations = action.data.products;
  });

  builder.addCase(showToastMessage, (state, action) => {
    state.toastMessage = action.payload;
  });

  builder.addCase(clearToastMessage, (state, action) => {
    state.toastMessage = null;
  });

  builder.addCase(showAppLoader, (state, action) => {
    state.isAppLoaderVisibile = true;
  });

  builder.addCase(hideAppLoader, (state, action) => {
    state.isAppLoaderVisibile = false;
  });

  builder.addCase(subscribeEmailSuccess, (state, action) => {
    state.isSubscriptionSuccessful = true;
  });

  builder.addCase(checkPincodeSuccess, (state, action) => {
    state.pincodeCheckResponse = action.data;
  });

  builder.addCase(clearPincodeStatus, (state, action) => {
    state.pincodeCheckResponse = "";
  });

  builder.addCase(getCollectionProductsSuccess, (state, action) => {
    state.sliderData = {
      ...state.sliderData,
      collections: {
        ...state.sliderData.collections,
        [action.data.id]: action.data.productList,
      },
    };
  });

  builder.addCase(getSubCategoriesForSliderSuccess, (state, action) => {
    let bagsCollection = [];

    [...state.categories].forEach((item)=> {
      if (item.name === 'Bags') {
        bagsCollection = [...bagsCollection, item]
      }
    });

    state.sliderData = {
      ...state.sliderData,
      categories: [...action.data, ...bagsCollection],
    };
  });
});
