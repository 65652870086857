import { useEffect, useState } from "react";
import ImageWrapper from "../../components/Image/ImageWrapper";
import { connect, useDispatch } from "react-redux";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

const BLOCK_CLASS = "strip-banner";

const Banner = (props) => {

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(()=> {
    window.addEventListener('resize', handleResize)
  }, [])

  function handleResize() {
    setInnerWidth(window.innerWidth);
  }

  return (
    <section
      className={
        props.placement == 2
          ? `${BLOCK_CLASS} ${BLOCK_CLASS}__bottom-margin`
          : `${BLOCK_CLASS}`
      }
    >
      <Carousel arrows={true}>
        {props.imgData.map((item) => {
          return (
            <Link to={item.redirect_link}>
              <ImageWrapper
                classes={`${BLOCK_CLASS}__img`}
                name={innerWidth < 600 ? item.mobile_name : item.desktop_name}
                extension={
                  innerWidth < 600
                    ? item.mobile_extenstion
                    : item.desktop_extension
                }
                alt={"Discounts and offers"}
                srcType="internal"
              />
            </Link>
          );
        })}
      </Carousel>
    </section>
  );
};

function mapStateToProps(state, ownProps) {
  const { categories, isMobileMenuOpen } = state;
  return { categories: categories, isMobileMenuOpen };
}

export default connect(mapStateToProps)(Banner);
