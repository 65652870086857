import React, { useEffect, useState } from "react";
import ImageWrapper from "../Image/ImageWrapper";
import Slider from "react-slick";
import Card from "../../components/Card/Card";
import { Link } from "react-router-dom";

const ProductSlider = (props) => {
  const BLOCK_CLASS = "product-slider";

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setInnerWidth(window.innerWidth);
  }

  var settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: innerWidth < 600 ? 2 : 4,
    slidesToScroll: 1,
  };

  return (
    <section className={`${BLOCK_CLASS}`}>
      {props.name ? (
        <div className="slider-title">
          <h4>{props.name}</h4>
        </div>
      ) : (
        ""
      )}

      <Slider {...settings}>
        {props.type === "product" &&
          props?.data?.map((product, i) => {
            return <Card data={product} key={`${i}` + "product"} />;
          })}

        {/* <div className="category-card"> */}
        {props.type === "category" &&
          props?.data?.map((item, i) => {
            let url =
              item.name === "Clothing"
                ? `collection/${item.name}`
                : `/products/Bags/${item.id}`;
            return (
              <div className="category-item" key={item.id}>
                <figure>
                  <Link to={url} state={item}>
                    {item?.images && item?.images[0]?.url !== undefined ? (
                      <ImageWrapper
                        externalUrl={item?.images[0]?.url}
                        alt={item.name}
                      />
                    ) : (
                      <ImageWrapper
                        name={"Women"}
                        alt={item.name}
                        srcType="internal"
                      />
                    )}
                  </Link>
                  <Link to={url} state={item}>
                    <figcaption>{item.name}</figcaption>
                  </Link>
                  <Link to={url} state={item}>
                    <div className="category-item__card-cta">
                      <p>Explore more</p>
                    </div>
                  </Link>
                </figure>
              </div>
            );
          })}
        {/* </div> */}
      </Slider>

      {props.ctaUrl ? (
        <div className="btn-wrapper">
          <a href={props.ctaUrl}>Explore more</a>
        </div>
      ) : null}
    </section>
  );
};

export default ProductSlider;
